ion-card {
  // -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  // box-shadow: 0 4px 4px rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.12);
  border: 1px solid var(--ion-border-color);
  margin: 16px 0 16px 0;

  .card-content-md,
  .card-content-ios {
    font-size: 1rem;
    padding-inline-start: 8px;
    padding-inline-end: 8px;
    padding-inline-top: 8px;

    p {
      font-size: 1rem;
    }
  }
}
