ion-tab-button {
  border-top: 2px solid transparent;
  // --color: var(--ion-color-danger);
  &.tab-selected {
    border-top: 2px solid var(--ion-color-primary-text);
    color: var(--ion-color-primary-text);
  }
}

@media(prefers-color-scheme: dark) {

  ion-tab-button {
    color: var(--ion-color-primary-text);
    &.tab-selected {
      border-top: 2px solid var(--ion-text-color);
      color: var(--ion-text-color);
    }
  }

}
