ion-button {
  // --padding-bottom: 0.935em;
  // --padding-top: 0.935em;
  --border-radius: 999px;
  --padding-end: 1.5em;
  --padding-start: 1.5em;
  text-transform: none;
  font-weight: 500;
  letter-spacing: 0.03em;

  --padding-bottom: 10px;
  --padding-top: 10px;
  min-height: 40px;

  &.button-has-icon-only {
    --padding-end: 1em;
    --padding-start: 1em;
  }

  &.button-outline {
    --border-width: var(--okc-btn-border-outline);
  }

  &.button-small {
    // --padding-bottom: 0.8em;
    // --padding-top: 0.8em;
    --padding-bottom: 4px;
    --padding-top: 4px;
    min-height: 32px;
  }

  &.button-large {
    min-height: 48px;
  }
}


.md {
  ion-button {
    &.button-small {
      font-size: min(0.8rem, 39px);
    }
  }
}

.ios {
  ion-button {
    font-size: 0.875em;

    &.button-small {
      font-size: min(0.8rem, 39px);
    }

    &.button-large {
      font-size: min(1.25rem, 60px);
    }
  }
}

@media(prefers-color-scheme: dark) {

  ion-button.button-outline.ion-color-primary::part(native),
  ion-button.button-clear.ion-color-primary::part(native) {
    color: var(--ion-color-primary-text) !important;
  }

  ion-button.button-outline.ion-color-secondary::part(native),
  ion-button.button-clear.ion-color-secondary::part(native) {
    color: var(--ion-color-secondary-text) !important;
  }

  ion-button.button-outline.ion-color-tertiary::part(native),
  ion-button.button-clear.ion-color-tertiary::part(native) {
    color: var(--ion-color-tertiary-text) !important;
  }

  ion-button.button-outline.ion-color-success::part(native),
  ion-button.button-clear.ion-color-success::part(native) {
    color: var(--ion-color-success-text) !important;
  }

  ion-button.button-outline.ion-color-success::part(native),
  ion-button.button-clear.ion-color-success::part(native) {
    color: var(--ion-color-success-text);
  }

  ion-button.button-outline.ion-color-danger::part(native),
  ion-button.button-clear.ion-color-danger::part(native) {
    color: var(--ion-color-danger-text) !important;
  }

  ion-button.button-outline.ion-color-warning::part(native),
  ion-button.button-clear.ion-color-warning::part(native) {
    color: var(--ion-color-warning-text) !important;
  }

  ion-button.button-outline.ion-color-info::part(native),
  ion-button.button-clear.ion-color-info::part(native) {
    color: var(--ion-color-info-text) !important;
  }

}
