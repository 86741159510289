ion-input {

  &.input-label-placement-floating {
    .label-text-wrapper  {
      color: var(--ion-color-medium);
    }
    &.label-floating {
      .label-text-wrapper  {
        font-weight: 700;
      }
    }
  }

}
