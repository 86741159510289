ion-header.ion-no-border {

  border-bottom: 1px solid var(--ion-border-color);

  // ion-toolbar {
    // background-color: var(--ion-color-white);
  // }

  &.header-collapse-condense-inactive {
    border-bottom: transparent;
    background-color: var(--ion-background-color);
    ion-toolbar {
      background-color: var(--ion-background-color);
    }
  }


}
