@font-face {
  font-family: "Nexa Bold";
  font-style: normal;
  font-weight: 700;
  src:
  url("../assets/fonts/nexa/nexa_bold-webfont.woff2") format("woff2"),
  url("../assets/fonts/nexa/nexa_bold-webfont.woff") format("woff"),
  url("../assets/fonts/nexa/Nexa_Bold.otf") format("otf"),
  local("Nexa Bold");
}

@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 400;
  src: local("Roboto"),
    url("../assets/fonts/roboto/Roboto-Regular.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 400;
  src: local("Roboto"),
    url("../assets/fonts/roboto/Roboto-Italic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 700;
  src: local("Roboto Bold"),
    url("../assets/fonts/roboto/Roboto-Bold.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 700;
  src: local("Roboto Bold"),
    url("../assets/fonts/roboto/Roboto-BoldItalic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 250;
  src: local("Roboto Thin"),
    url("../assets/fonts/roboto/Roboto-Thin.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 250;
  src: local("Roboto Thin"),
    url("../assets/fonts/roboto/Roboto-ThinItalic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 300;
  src: local("Roboto Light"),
    url("../assets/fonts/roboto/Roboto-Light.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 300;
  src: local("Roboto Light"),
    url("../assets/fonts/roboto/Roboto-LightItalic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  src: local("Roboto Medium"),
    url("../assets/fonts/roboto/Roboto-Medium.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 500;
  src: local("Roboto Medium"),
    url("../assets/fonts/roboto/Roboto-MediumItalic.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: normal;
  font-weight: 900;
  src: local("Roboto Black"),
    url("../assets/fonts/roboto/Roboto-Black.woff") format("woff");
}
@font-face {
  font-family: "Roboto";
  font-style: italic;
  font-weight: 900;
  src: local("Roboto Black"),
    url("../assets/fonts/roboto/Roboto-BlackItalic.woff") format("woff");
}
