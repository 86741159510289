// For information on how to create your own theme, please see:
// http://ionicframework.com/docs/theming/

:root {

  //////
  //// typography
  //////
  --ion-font-family: 'Roboto';

  //////
  //// colors, palette
  //////

  --ion-color-primary: #3A4BC4;
  --ion-color-primary-rgb: 58, 75, 196;
  --ion-color-primary-contrast: #ffffff;
  --ion-color-primary-contrast-rgb: 255, 255, 255;
  --ion-color-primary-shade: #3342ac;
  --ion-color-primary-tint: #4e5dca;

  --ion-color-primary-text: var(--ion-color-primary);

  --ion-color-secondary: #15248A;
  --ion-color-secondary-rgb: 21, 36, 138;
  --ion-color-secondary-contrast: #ffffff;
  --ion-color-secondary-contrast-rgb: 255, 255, 255;
  --ion-color-secondary-shade: #122079;
  --ion-color-secondary-tint: #2c3a96;

  --ion-color-secondary-text: var(--ion-color-secondary);

  --ion-color-tertiary: #00AEAE;
  --ion-color-tertiary-rgb: 0, 174, 174;
  --ion-color-tertiary-contrast: #ffffff;
  --ion-color-tertiary-contrast-rgb: 255, 255, 255;
  --ion-color-tertiary-shade: #009999;
  --ion-color-tertiary-tint: #1ab6b6;

  --ion-color-tertiary-text: var(--ion-color-tertiary);

  --ion-color-success: #128732;
  --ion-color-success-rgb: 18, 135, 50;
  --ion-color-success-contrast: #ffffff;
  --ion-color-success-contrast-rgb: 255, 255, 255;
  --ion-color-success-shade: #10772c;
  --ion-color-success-tint: #2a9347;

  --ion-color-success-text: var(--ion-color-success);

  --ion-color-warning: #FFDF3D;
  --ion-color-warning-rgb: 255, 223, 61;
  --ion-color-warning-contrast: #000000;
  --ion-color-warning-contrast-rgb: 0, 0, 0;
  --ion-color-warning-shade: #e0c436;
  --ion-color-warning-tint: #ffe250;

  --ion-color-warning-text: var(--ion-color-warning);

  --ion-color-danger: #DF331C;
  --ion-color-danger-rgb: 223, 51, 28;
  --ion-color-danger-contrast: #ffffff;
  --ion-color-danger-contrast-rgb: 255, 255, 255;
  --ion-color-danger-shade: #c42d19;
  --ion-color-danger-tint: #e24733;

  --ion-color-danger-text: var(--ion-color-danger);

  --ion-color-light: #F5F5F7;
  --ion-color-light-rgb: 245, 245, 247;
  --ion-color-light-contrast: #000000;
  --ion-color-light-contrast-rgb: 0, 0, 0;
  --ion-color-light-shade: #d8d8d9;
  --ion-color-light-tint: #f6f6f8;

  --ion-color-medium: #71758F;
  --ion-color-medium-rgb: 113, 117, 143;
  --ion-color-medium-contrast: #ffffff;
  --ion-color-medium-contrast-rgb: 255, 255, 255;
  --ion-color-medium-shade: #63677e;
  --ion-color-medium-tint: #7f839a;

  --ion-color-danger-text: var(--ion-color-medium);

  --ion-color-dark: #393B47;
  --ion-color-dark-rgb: 57, 59, 71;
  --ion-color-dark-contrast: #ffffff;
  --ion-color-dark-contrast-rgb: 255, 255, 255;
  --ion-color-dark-shade: #32343e;
  --ion-color-dark-tint: #4d4f59;

  // new colors
  --ion-color-info: #3AC6DE;
  --ion-color-info-rgb: 58, 198, 222;
  --ion-color-info-contrast: #000000;
  --ion-color-info-contrast-rgb: 0, 0, 0;
  --ion-color-info-shade: #33aec3;
  --ion-color-info-tint: #4ecce1;

  --ion-color-info-text: var(--ion-color-info);

  --ion-color-white: #ffffff;
	--ion-color-white-rgb: 255,255,255;
	--ion-color-white-contrast: #000000;
	--ion-color-white-contrast-rgb: 0,0,0;
	--ion-color-white-shade: #e0e0e0;
	--ion-color-white-tint: #ffffff;


  // new colors, backgrounds
  --ion-color-info-bg: #D8F4F8;
  --ion-color-warning-bg: #FFF2B1;

  --ion-color-danger-bg: #F9D6D1;
  --ion-color-success-bg: #D9EFDF;

  // TODO add all variants and colors here


  //////
  //// colors, app
  //////


  --ion-background-color: #F5F5F7;
  --ion-background-color-rgb: rgba(245, 245, 247);

  --ion-background-color-lightest: #ffffff;

  --ion-text-color: var(--ion-color-dark);
  --ion-text-color-rgb: var(--ion-color-dark-rgb);

  --ion-color-link: var(--ion-color-primary);

  --ion-border-color: #EAEAEE; // TO-DO neutral-100;

  --ion-item-background: var(--ion-background-color-lightest);

  --ion-toolbar-background: var(--ion-item-background);

  --ion-card-color: var(--ion-text-color);

  //////
  //// blocks
  //////

  --ion-padding: 16px;
  --ion-margin: 16px;

  --ion-grid-padding: 8px;
  --ion-grid-column-padding: 8px;



  //////
  //// okcargo theme vars
  //////

  --okc-background-color: #F5F5F7;
  --okc-btn-border-outline: 0.9px;

  --fs-xs: 0.75rem;
  --fs-sm: 0.875rem;


  /// dark mode

  @media(prefers-color-scheme: dark) {
    --okc-background-color: #2B2C36;
    --ion-background-color-lightest: #1e1e1e;

    // --ion-item-background: var(--ion-background-color-lightest);


    --ion-border-color: #55586B;


    // --ion-color-white: var(--ion-item-background);

    --ion-color-medium: #34353d; //var(--ion-color-medium);
    --ion-color-light: #393B47;

    --ion-color-primary: #3A4BC4;
    --ion-color-primary-rgb: 58, 75, 196;
    --ion-color-primary-contrast: #ffffff;
    --ion-color-primary-contrast-rgb: 255, 255, 255;
    --ion-color-primary-shade: #3342ac;
    --ion-color-primary-tint: #4e5dca;

    --ion-text-color: #EAEAEE;

    --ion-color-info: #187E90;
    --ion-color-info-rgb: 24,126,144;
    --ion-color-info-contrast: #ffffff;
    --ion-color-info-contrast-rgb: 255,255,255;
    --ion-color-info-shade: #156f7f;
    --ion-color-info-tint: #2f8b9b;

    --ion-color-primary-text: #B0B7E8;
    --ion-color-secondary-text: #485CE3;
    --ion-color-tertiary-text: #79FFFF;
    --ion-color-success-text: #B4DFC0;
    --ion-color-warning-text: #FFF2B1;
    --ion-color-danger-text: #F4AEA4;
    --ion-color-info-text: #B0E8F2;

    // TODO más oscuro
    --ion-color-info-bg: #105460;
    --ion-color-warning-bg: #BE9E00;

    // TODO confirm ok
    --ion-color-danger-bg: #881F11;
    --ion-color-success-bg: #085C1F;

  }
}
