.ios {}

.md {}

body {
  font-family: Roboto;
}


ion-title,
h1,
h2 {
  font-family: "Nexa Bold";
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 700;
}

// Apply text color when use .ion-color-[color_name] in this elements.
// TODO review and expand elements
p,
div,
ul,
li,
h1,
h2,
h3,
h4,
h5,
h6 {
  color: var(--ion-color-base);
}

ion-item,
ion-card,
// ion-menu,
ion-tabs,
ion-toolbar {
  --ion-background-color: var(--ion-background-color-lightest);
}

ion-content {
  --ion-background-color: var(--okc-background-color);
}

@media screen and (min-width: 768px) {
  ion-menu {
    ion-content {
      --ion-background-color: var(--ion-background-color-lightest);
    }
  }

  ion-item.active {
    --background: var(--okc-background-color);
  }
}

//////
/// extend ionic basics

.ion-color-info {
  --ion-color-base: var(--ion-color-info);
  --ion-color-base-rgb: var(--ion-color-info-rgb);
  --ion-color-contrast: var(--ion-color-info-contrast);
  --ion-color-contrast-rgb: var(--ion-color-info-contrast-rgb);
  --ion-color-shade: var(--ion-color-info-shade);
  --ion-color-tint: var(--ion-color-info-tint);
}

.ion-color-white {
  --ion-color-base: var(--ion-color-white);
  --ion-color-base-rgb: var(--ion-color-white-rgb);
  --ion-color-contrast: var(--ion-color-white-contrast);
  --ion-color-contrast-rgb: var(--ion-color-white-contrast-rgb);
  --ion-color-shade: var(--ion-color-white-shade);
  --ion-color-tint: var(--ion-color-white-tint);
}

.ion-padding-top-0 {
  padding-top: 0;
}

.ion-padding-vertical-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.ion-padding-horizontal-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.ion-grid-padding {
  padding: var(--ion-grid-padding) !important;
}

.ion-margin-top-0 {
  margin-top: 0 !important;
}

.ion-margin-bottom-0 {
  margin-bottom: 0;
}

.ion-margin-negative {
  margin: -16px;
}

.ion-margin-horizontal-negative {
  margin-left: -16px;
  margin-right: -16px;
}

///
//////

//////
/// menu & tabs, variations depending on screen size
///

img.menu-logo {
  height: 40px;
  width: auto;
  margin-top: 8px;
}

ion-split-pane {
  --side-width: 280px;
  --side-max-width: 280px;

  --border: 1px solid var(--ion-border-color);
}

@media screen and (min-width: 768px) {
  .okc-tabs-container {
    display: none;
  }
}


///
//////

.text-medium {
  color: var(--ion-color-medium);
}


.fs-xs {
  font-size: var(--fs-xs) !important;
}

.fs-sm {
  font-size: var(--fs-sm) !important;
}

.ff-base {
  font-family: var(--ion-font-family);
}

.fw-700 {
  font-weight: 700;
}

.bar-system-status {
  @extend.fs-sm;
  padding: var(--ion-padding);
  border-bottom: 1px solid var(--ion-border-color);
  margin-left: calc(0px - var(--ion-margin));
  margin-right: calc(0px - var(--ion-margin));
}

.block-content-round {
  background-color: var(--ion-background-color-lightest);
  border-radius: var(--ion-padding);
  padding: var(--ion-grid-padding) var(--ion-grid-padding) var(--ion-grid-padding) var(--ion-grid-padding);
  margin: var(--ion-margin) 0;

}

.max-width-500 {
  max-width: 500px;
  margin: 0 auto;
}

// TODO remove, use message-ui.component
.ui-messages {
  background-color: var(--ion-color-info-bg);
  border-radius: var(--ion-padding);
  border: 1px solid var(--ion-color-info);
  padding: var(--ion-grid-padding) var(--ion-padding) var(--ion-grid-padding) var(--ion-grid-padding);
  margin: var(--ion-margin) 0;

  font-size: var(--fs-sm);
  font-weight: 700;
  text-align: center;
}

.ico-big {
  font-size: 48px;
  ;
}

a {
  color: var(--ion-color-primary-text);
}
