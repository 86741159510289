ion-action-sheet.action-sheet {
  --background: var(--ion-background-color-lightest);
}

.md {
  ion-action-sheet.action-sheet {
    .action-sheet-wrapper {
      border-radius: 16px 16px 0 0; // TO-DO @oalvarez review, don't work
      overflow: hidden;
    }
  }
}
